import { render, staticRenderFns } from "./BookingPageActions.vue?vue&type=template&id=793845a4&scoped=true&"
import script from "./BookingPageActions.vue?vue&type=script&lang=ts&"
export * from "./BookingPageActions.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "793845a4",
  null
  
)

export default component.exports