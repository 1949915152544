var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-expansion-panels',{attrs:{"accordion":"","flat":""},model:{value:(_vm.panelModel),callback:function ($$v) {_vm.panelModel=$$v},expression:"panelModel"}},_vm._l((_vm.actions),function(action,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',{attrs:{"hide-actions":""}},[_c('template',{slot:"default"},[_c('actions-expansion-panel-header',{attrs:{"is-open":_vm.isPanelOpened(i),"action":action}})],1)],2),_c('v-expansion-panel-content',[_c('template',{slot:"default"},[(_vm.deletingAction)?_c('div',[_c('v-skeleton-loader',_vm._b({attrs:{"type":"list-item-three-line"}},'v-skeleton-loader',_vm.attrs,false))],1):_c('div',[(
                action.when === 'if_booking_rescheduled' || action.when === 'if_booking_cancelled'
              )?_c('actions-notif-branch-with-fork',{attrs:{"actions":action}}):_vm._e(),(
                action.when === 'after_new_booking' ||
                  action.when === 'after_appointment_ends' ||
                  action.when === 'reminders_before_the_booking'
              )?_c('actions-notif-branch',{attrs:{"actions":action}}):_vm._e()],1)])],2)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }